import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.visible = $event),
    title: $props.title,
    width: $props.width,
    "close-on-click-modal": false,
    "align-center": "",
    onClose: $options.closeDialog
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["modelValue", "title", "width", "onClose"]);
}