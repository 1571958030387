import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center"
  }
};
const _hoisted_2 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.visible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.visible = $event),
    title: "提示",
    width: "25%",
    "align-center": "",
    center: "",
    "close-on-click-modal": false,
    onClose: $options.cancel
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = $event => $data.visible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.deleteItem
    }, {
      default: _withCtx(() => [_createTextVNode(" 确定")]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.content), 1)]),
    _: 1
  }, 8, ["modelValue", "onClose"]);
}