export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  emits: ['get-visible', 'confirm-deletion'],
  data() {
    return {
      visible: this.dialogVisible
    };
  },
  methods: {
    deleteItem() {
      this.$emit('confirm-deletion');
    },
    cancel() {
      this.visible = false;
      this.$emit('get-visible', this.visible);
    }
  }
};