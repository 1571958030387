import { defineComponent } from 'vue';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default defineComponent({
  components: {
    ElConfigProvider
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  mounted() {
    this.getAdminInfo();
  },
  methods: {
    async getCsrfToken() {
      try {
        await this.$store.dispatch('getCsrfToken');
      } catch (err) {
        return;
      }
    },
    async getAdminInfo() {
      try {
        this.getCsrfToken();
        await this.$store.dispatch('getAdminInfo');
      } catch (err) {
        return;
      }
    }
  }
});